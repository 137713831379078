/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { KTIcon, toAbsoluteUrl } from '../../../../_metronic/helpers'

import Network from '../../../helpers/Network'
import { Popover } from '@mantine/core'
import { DropdownStatus } from '../../../patterns/DropdownStatus'
import SkeletonComponent from '../../../patterns/SkeletonComponent'
import { LocalStorageProps, MapProps, TableProps } from '../../../helpers/_models'
import { AddModal, DeleteModal, EditModal } from '../../../modules/actions/Modals'
import PlayerModal from './PlayerModal'
import { ThemeModeComponent } from '../../../../_metronic/assets/ts/layout'
import { useNavigate } from 'react-router-dom'
import Paginations from '../../../patterns/Paginations'

const TablesWidget: React.FC<TableProps> = ({
  className,
  data,
  setData,
  tablehead,
  mountData,
  addedButton = true,
  setCountPerPage,
  countPerPage,
  setActivePage,
  activePage,
  totalData,
  userInfo
}) => {
  const navigate = useNavigate()
  const [addPlayersValue, setAddPlayersValue] = useState({
    operator_id: userInfo?.data.operator_id,
    operator_player_id: '',
    operator_player_name: '',
    ban: false,
    ban_reason: '',
  })
  const mode1 = ThemeModeComponent.getMode()
  const [openAddModal, setOpenAddModal] = useState(false)
  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const [openEditModal, setOpenEditModal] = useState(false)
  const [inputTypes, setInputTypes] = useState('')
  const [loading, setLoading] = useState(false)
  const [checkedList, setCheckedList] = useState<any[]>([])
  const [currentData, setCurrentData] = useState<object>({})
  const [batchProcess, setBatchProcess] = useState([
    {
      name: 'Delete Selected',
      icon: <KTIcon iconName='trash' iconType='duotone' className='fs-1' />,
      id: 'deleteSelected',
    },
    // {
    //   name: 'Change Status',
    //   icon: <KTIcon iconName='shield' iconType='duotone' className='fs-1' />,
    //   id: 'changeStatus',
    // },
  ])

  const addedPlayersHandler = async () => {
    try {
      await Network.postData('/players', addPlayersValue).then((res) => {
        setOpenAddModal(false)
      })
      await mountData()
    } catch (error) { }
  }
  const editPlayersHandler = async (id: number) => {
    try {
      await Network.putData(`/players/${id}`, addPlayersValue).then((res) => {
        setOpenEditModal(false)
      })
      await mountData()
    } catch (error) { }
  }
  const banPlayers = async (id: number) => {
    try {
      await Network.putData(`players/${id}/ban`, {
        ban: addPlayersValue.ban,
        ban_reason: addPlayersValue.ban_reason,
      })
      await mountData()
      setOpenEditModal(false)
    } catch (error) {
      console.log(error)
    }
  }
  const deletedPlayersHandler = async (id: number) => {
    try {
      await Network.deleteData(`/players/${id}/player`).then((res) => {
        setOpenDeleteModal(false)
      })
      await mountData()
    } catch (error) { }
  }
  const batchProcessHandler = async (type: string, checkedList: any) => {
    setLoading(true)
    if (type === 'deleteSelected') {
      try {
        for (const item of checkedList) {
          await Network.deleteData(`/players/${item.id}/player`).then(async (res) => {
            await mountData()
            setCheckedList([])
          })
        }
        setLoading(false)
      } catch (error) {
        console.log(error)
      }
    }
    if (type === 'changeStatus') {
      try {
        for (const item of checkedList) {
          await Network.putData(`/players/${item.id}`, {
            status: item.status === 'active' ? 'passive' : 'active',
          }).then(async (res) => {
            await mountData()
            setCheckedList([])
          })
        }
        setLoading(false)
      } catch (error) {
        console.log(error)
      }
    }
  }
  const [mode, setMode] = useState(localStorage.getItem('kt_theme_mode_menu'))
  useEffect(() => {
    setMode(localStorage.getItem('kt_theme_mode_menu'))
  }, [mode])


  return (
    <div className={`card ${className} h-100`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Players</span>
          <span className='text-muted mt-1 fw-semibold fs-7'>{totalData} Players</span>
        </h3>
        <div className='card-toolbar'>
          {checkedList.length > 0 && addedButton ? (
            <Popover width={300} position='left' withArrow shadow='md'>
              <Popover.Target>
                <div className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'>
                  <KTIcon iconName='switch' className='fs-3' />
                </div>
              </Popover.Target>
              <Popover.Dropdown sx={{ padding: '0' }}>
                {batchProcess.map((item) => {
                  return (
                    <div
                      className='d-flex justify-content-start flex-column cursor-pointer border-bottom hover-bg-1'
                      id={item.id}
                      key={item.id}
                      onClick={(e: React.MouseEvent<HTMLDivElement>) =>
                        batchProcessHandler(e.currentTarget.id, checkedList)
                      }
                    >
                      <div className='d-flex justify-content-start align-items-center p-2'>
                        <span className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'>
                          {item.icon}
                        </span>
                        <span className='ms-2 fs-5 text'>{item.name}</span>
                      </div>
                    </div>
                  )
                })}
              </Popover.Dropdown>
            </Popover>
          ) : (
            ''
          )}
          {/* begin::Menu */}

          <button
            type='button'
            className='btn me-1 btn-sm btn-icon btn-color-primary btn-active-light-primary btn-bg-light'
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='top-end'
          >
            <KTIcon iconName='category' className='fs-2' />
          </button>
          <DropdownStatus setList={setData} url={`/players/${userInfo?.data.operator_id}/operator`} data={data} />
          {/* end::Menu */}
          {addedButton && (
            <a
              href='#'
              className='btn btn-sm btn-light-primary'
              onClick={() => {
                setOpenAddModal(true)
                setInputTypes('save')
                setAddPlayersValue({
                  operator_id: userInfo?.data.operator_id,
                  operator_player_id: '',
                  operator_player_name: '',
                  ban: false,
                  ban_reason: '',
                })
              }}
            >
              <KTIcon iconName='plus' className='fs-3' />
              New Player

            </a>
          )}
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3 d-flex flex-column justify-content-between'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          {data.total > 0 ? (
            <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
              {/* begin::Table head */}
              <thead>
                <th className='w-25px'>
                  <div className='form-check form-check-sm form-check-custom form-check-solid p-0 '>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      value='1'
                      data-kt-check='true'
                      data-kt-check-target='.widget-9-check'
                      checked={data.data.length === checkedList.length}
                      onChange={(e) => {
                        if (e.currentTarget.checked) {
                          setCheckedList(data.data)
                        } else {
                          setCheckedList([])
                        }
                      }}
                    />
                  </div>
                </th>
                {tablehead.map((item: MapProps['item'], index: number) => {
                  return (
                    <th
                      key={index}
                      className={`min-w-140px fw-bold ps-2 ${index === tablehead.length - 1 ? 'text-end' : ''
                        } `}
                    >
                      {item}
                    </th>
                  )
                })}
              </thead>
              {/* end::Table head */}
              {/* begin::Table body */}
              {!loading ? (
                <tbody>
                  {data.data.map((item: any, index: number) => {
                    return (
                      <tr key={index}>
                        <td>
                          <div className='form-check form-check-sm form-check-custom form-check-solid'>
                            <input
                              className='form-check-input widget-9-check'
                              type='checkbox'
                              value='1'
                              checked={checkedList.some((i) => i.id === item.id)}
                              onChange={(e) => {
                                if (e.currentTarget.checked) {
                                  setCheckedList((prev) => [...prev, item])
                                } else {
                                  const newlist = checkedList.filter((i) => i.id !== item.id)
                                  setCheckedList(newlist)
                                }
                              }}
                            />
                          </div>
                        </td>
                        <td>
                          <div className='d-flex align-items-center'>
                            {/* <div className='symbol symbol-45px me-5'>
                              <img src={toAbsoluteUrl(item.image)} alt='' />
                            </div> */}
                            <div
                              className='d-flex justify-content-start flex-column cursor-pointer'
                              onClick={() => {
                                navigate(`/players/detail/${item.id}`)
                              }}
                            >
                              <span className='text-dark fw-bold text-hover-primary fs-6'>
                                {item.operator_player_name}
                              </span>
                              <span className='text-muted fw-semibold text-muted d-block fs-7'>
                                {item.operator_player_id}
                              </span>
                            </div>
                          </div>
                        </td>

                        <td>
                          <div className='d-flex align-items-center'>
                            {/* <div className='symbol symbol-45px me-5'>
                              <img src={toAbsoluteUrl('/media/avatars/300-14.jpg')} alt='' />
                            </div> */}
                            <div className='d-flex justify-content-start align-items-center'>
                              <Popover position='bottom' withArrow shadow='md'>
                                <Popover.Target>
                                  <a
                                    href='#'
                                    className='ms-1 btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                  >
                                    <KTIcon
                                      iconName='text-circle'
                                      className='fs-3'
                                      iconType='duotone'
                                    />
                                  </a>
                                </Popover.Target>
                                <Popover.Dropdown
                                  sx={{ padding: '0', color: mode1 === 'dark' ? '#2b2b40' : '' }}
                                >
                                  <div className='d-flex flex-column'>
                                    <p
                                      className='m-0 border-bottom border-primary'
                                      style={{ padding: '4px', fontWeight: 'bold' }}
                                    >
                                      Player Relations
                                    </p>
                                    <div className='d-flex flex-column allowed-list'>
                                      <div className='d-flex align-items-center p-2'>
                                        <span className='fw-bold'>Name: </span>
                                        <span className='ms-1'> {item.operator_relation.name}</span>
                                      </div>
                                      <div className='d-flex align-items-center p-2'>
                                        <span className='fw-bold'>Endpoint: </span>
                                        <span className='ms-1'>
                                          {item.operator_relation.api_endpoint}
                                        </span>
                                      </div>
                                      <div className='d-flex align-items-center p-2'>
                                        <span className='fw-bold'>Api Key: </span>
                                        <span className='ms-1 text-wrap'>
                                          {item.operator_relation.api_key}
                                        </span>
                                      </div>
                                      <div className='d-flex align-items-center p-2'>
                                        <span className='fw-bold'>Api Secret: </span>
                                        <span className='ms-1 text-wrap'>
                                          {item.operator_relation.api_secret}
                                        </span>
                                      </div>
                                      <div className='row m-0 justify-content-center align-items-center'>
                                        <div className='col-5 allow-api-list d-flex justify-content-center align-items-center p-2 m-0 text-center fw-bold'>
                                          <span className='p-1 w-100'>API Allowed IPS</span>
                                        </div>
                                        <div className='col-7 p-0 m-0 allowed-list'>
                                          {item?.operator_relation?.api_allowed_ips?.map(
                                            (i: string, index: number) => {
                                              return (
                                                <li
                                                  className='d-flex align-items-center p-2'
                                                  key={index}
                                                >
                                                  <span className='bullet bullet-dot bg-primary me-5'></span>
                                                  {i}
                                                </li>
                                              )
                                            }
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </Popover.Dropdown>
                              </Popover>

                              <div>
                                <span className='text-muted fw-semibold text-muted d-block fs-7'>
                                  Operator Name
                                </span>
                                <span className='fw-bold d-block fs-7'>
                                  {item.operator_relation.name}
                                </span>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className='d-flex align-items-center'>
                            <div className='d-flex justify-content-start align-items-center'>
                              {!item.ban ? (
                                <span className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'>
                                  <KTIcon iconName='shield-tick' className='fs-3 text-success' />
                                </span>
                              ) : (
                                <span className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'>
                                  <KTIcon iconName='shield-cross' className='fs-3 text-danger' />
                                </span>
                              )}

                              {item.ban && (
                                <div>
                                  <span className='text-muted fw-semibold text-muted d-block fs-7'>
                                    Ban Reason
                                  </span>
                                  <span className='fw-bold d-block fs-7'>{item.ban_reason}</span>
                                </div>
                              )}
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className='d-flex justify-content-end flex-shrink-0'>
                            <span
                              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                              onClick={() => {
                                navigate(`/players/detail/${item.id}`)
                              }}
                            >
                              <KTIcon iconName='dots-square' className='fs-3' iconType='duotone' />
                            </span>
                            <a
                              href='#'
                              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                              onClick={() => {
                                setOpenEditModal(true)
                                setCurrentData(item)
                                setInputTypes('edit')
                                setAddPlayersValue({
                                  operator_id: item.operator_id,
                                  operator_player_id: item.operator_player_id,
                                  operator_player_name: item.operator_player_name,
                                  ban: item.ban,
                                  ban_reason: item.ban_reason,
                                })
                              }}
                            >
                              <KTIcon iconName='pencil' iconType='duotone' className='fs-3' />
                            </a>
                            <a
                              href='#'
                              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                              onClick={() => {
                                setOpenDeleteModal(true)
                                setCurrentData(item)
                              }}
                            >
                              <KTIcon iconName='trash' iconType='duotone' className='fs-3' />
                            </a>
                          </div>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              ) : (
                <SkeletonComponent _count={data.total} />
              )}

              {/* end::Table body */}
            </table>
          ) : (
            <div className='w-100'>Herhangi bir Data bulunmuyor</div>
          )}

          {/* end::Table */}
        </div>
        {/* end::Table container */}
        {/* begin::Pagination*/}
        <div className='d-flex justify-content-end w-100 '>
          {totalData && (
            <Paginations
              activePage={activePage}
              setActivePage={setActivePage}
              countPerPage={countPerPage}
              setCountPerPage={setCountPerPage}
              total={Math.ceil(totalData / countPerPage)}
            />
          )}
        </div>
        {/* end::Pagination*/}
      </div>
      {/* begin::Body */}
      {/* Modals */}
      {openAddModal && (
        <AddModal
          senderFunction={addedPlayersHandler}
          title={'Added Player'}
          openModal={openAddModal}
          setOpenModal={setOpenAddModal}
          body={
            <PlayerModal
              addPlayersValue={addPlayersValue}
              setAddPlayersValue={setAddPlayersValue}
              inputTypes={inputTypes}
            />
          }
          inputTypes={inputTypes}
        />
      )}
      {openDeleteModal && (
        <DeleteModal
          senderFunction={deletedPlayersHandler}
          openModal={openDeleteModal}
          setOpenModal={setOpenDeleteModal}
          currentData={currentData}
        />
      )}
      {openEditModal && (
        <EditModal
          senderFunction={editPlayersHandler}
          title={'Edit Player'}
          openModal={openEditModal}
          setOpenModal={setOpenEditModal}
          currentData={currentData}
          inputTypes={inputTypes}
          banPlayers={banPlayers}
          body={
            <PlayerModal
              addPlayersValue={addPlayersValue}
              setAddPlayersValue={setAddPlayersValue}
              inputTypes={inputTypes}
            />
          }
        />
      )}
    </div>
  )
}

export { TablesWidget }
