/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {KTIcon, toAbsoluteUrl} from '../../../../_metronic/helpers'
import {DropdownStatus} from '../../../patterns/DropdownStatus'
import SkeletonComponent from '../../../patterns/SkeletonComponent'
import {TableProps} from '../../../helpers/_models'
import {useNavigate} from 'react-router-dom'

const TablesWidget1: React.FC<TableProps> = ({
  className,
  data,
  setData,
  tablehead,
  mountData,
  addedButton = true,
  userInfo
}) => {
  const navigate = useNavigate()

  const [mode, setMode] = useState(localStorage.getItem('kt_theme_mode_menu'))
  useEffect(() => {
    setMode(localStorage.getItem('kt_theme_mode_menu'))
  }, [mode])
console.log(data)
  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Games</span>
          <span className='text-muted mt-1 fw-semibold fs-7'>{data.total} Games</span>
        </h3>
        <div className='card-toolbar'>
          {/* begin::Menu */}

          <button
            type='button'
            className='btn me-1 btn-sm btn-icon btn-color-primary btn-active-light-primary btn-bg-light'
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='top-end'
          >
            <KTIcon iconName='category' className='fs-2' />
          </button>
          <DropdownStatus setList={setData} data={data} url={`/operator-games/${userInfo?.data?.operator_id}`} />
          {/* end::Menu */}
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          {data.total > 0 ? (
            <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
              {/* begin::Table head */}
              <thead>
                {tablehead.map((item: any, index: number) => {
                  return (
                    <th
                      key={index}
                      className={`min-w-140px fw-bold ps-2 ${
                        index === tablehead.length - 1 ? 'text-end' : ''
                      } `}
                    >
                      {item}
                    </th>
                  )
                })}
              </thead>
              {/* end::Table head */}
              {/* begin::Table body */}

              <tbody>
                {data.data
                  .filter((item: any, index: number) => {
                    if (index < 4) {
                      return item
                    }
                  })
                  .map((item: any, index: number) => {
                    return (
                      <tr key={index}>
                        <td>
                          <div className='d-flex align-items-center'>
                            <div className='symbol symbol-45px me-5'>
                              <img src={toAbsoluteUrl(item.game_relation.image)} alt='' />
                            </div>
                            <div className='d-flex justify-content-start flex-column'>
                              <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
                                {item.game_relation.name}
                              </a>
                              <span className='text-muted fw-semibold text-muted d-block fs-7'>
                                {item.game_relation.short_name}
                              </span>
                            </div>
                          </div>
                        </td>   
                        <td className='text-end'>
                          <div className='d-flex align-items-center justify-content-end'>
                            <div className='d-flex justify-content-start flex-column'>
                              {item.game_relation.status === 'active' ? (
                                <span className='badge badge-light-success fs-8 fw-bold my-2'>
                                  Active
                                </span>
                              ) : (
                                <span className='badge badge-light-danger fs-8 fw-bold my-2'>
                                  Passive
                                </span>
                              )}
                            </div>
                          </div>
                        </td>
                      </tr>
                    )
                  })}
              </tbody>

              {/* end::Table body */}
            </table>
          ) : (
            <div className='w-100'>Herhangi bir Data bulunmuyor</div>
          )}

          {/* end::Table */}
        </div>
        {/* end::Table container */}
        {data.total > 4 && (
          <div className='d-flex justify-content-center'>
            <button
              className='btn btn-primary'
              onClick={() => {
                navigate('/actions/game/list')
              }}
            >
              See All Games
            </button>
          </div>
        )}
      </div>
      {/* begin::Body */}
      {/* Modals */}
    </div>
  )
}

export {TablesWidget1}
