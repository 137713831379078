/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import { useIntl } from 'react-intl'
import { SidebarMenuItemWithSub } from './SidebarMenuItemWithSub'
import { SidebarMenuItem } from './SidebarMenuItem'

const SidebarMenuMain = () => {
  return (
    <>
      <SidebarMenuItem
        to='/dashboard'
        icon='home-3'
        title="Homepage"
        // title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        fontIcon='bi-briefcase-fill'
      />
      <SidebarMenuItem to='/players' icon='people' title='Players' fontIcon='duotone' />
      <SidebarMenuItem to='/search' icon='shield-search' title='Search' fontIcon='duotone' />
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Actions</span>
        </div>
      </div>
    
      <SidebarMenuItemWithSub
        to='/actions/game'
        title='Game Actions'
        icon='abstract-27'
        fontIcon='bi-person'
      >
        <SidebarMenuItem to='/actions/game/list' title='Game List' hasBullet={true} />
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub
        to='/actions/service'
        title='Service Actions'
        icon='abstract-9'
        fontIcon='bi-person'
      >
        <SidebarMenuItem to='/actions/service/countries' title='Countries' hasBullet={true} />
        <SidebarMenuItem to='/actions/service/currencies' title='Currencies' hasBullet={true} />
        {/* <SidebarMenuItem to='/actions/service/languages' title='Languages' hasBullet={true} /> Language Servisi Yok */}
      </SidebarMenuItemWithSub>
      

     

    </>
  )
}

export { SidebarMenuMain }
