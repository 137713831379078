/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from 'react'
import Network from '../helpers/Network'
type Props = {
  setList: any
  list?: any
  url: string
  data?: any
  relation?: any
}
export function DropdownStatus({ setList, list, url, data, relation }: Props) {
  const [filter, setFilter] = useState('0')
  const filterHandler = async () => {
    if (filter === '0') {
      await Network.getData(url).then((res) => setList(res))
    }
    if (filter === '1') {
      await Network.getData(url).then((res) => {
        const list = res.data.filter(
          (i: { ban?: boolean; relation: { status?: string } }) => {
            if (i.ban === true || i.ban === false) {
              return !i?.ban
            }
            if (i[relation]?.status) {
              return i[relation]?.status === 'active'
            }

          }
        )
        setList({ data: list, total: data?.total })
      })
    }

    if (filter === '2') {
      await Network.getData(url).then((res) => {
        const list = res.data.filter(
          (i: { ban?: boolean; relation: { status?: string } }) => {
            if (i.ban === true || i.ban === false) {
              return i.ban
            }
            if (i[relation]?.status) {
              return i[relation].status === 'passive'
            }
          })
        setList({ data: list, total: data.total })
      })
    }
  }

  return (
    <div className='menu menu-sub menu-sub-dropdown w-250px w-md-300px' data-kt-menu='true'>
      <div className='px-7 py-5'>
        <div className='fs-5 text-dark fw-bolder'>Filter Options</div>
      </div>

      <div className='separator border-gray-200'></div>

      <div className='px-7 py-5'>
        <div className='mb-10'>
          <label className='form-label fw-bold'>Status:</label>

          <div>
            <select
              className='form-select form-select-solid'
              data-kt-select2='true'
              data-placeholder='Select option'
              data-allow-clear='true'
              defaultValue={'0'}
              onChange={(e) => setFilter(e.target.value)}
            >
              <option value='0'>All</option>
              <option value='1'>Active</option>
              <option value='2'>Passive</option>
            </select>
          </div>
        </div>

        <div className='d-flex justify-content-end'>
          <button
            type='reset'
            className='btn btn-sm btn-light btn-active-light-primary me-2'
            data-kt-menu-dismiss='true'
          >
            Close
          </button>

          <button
            onClick={filterHandler}
            type='submit'
            className='btn btn-sm btn-primary'
            data-kt-menu-dismiss='true'
          >
            Apply
          </button>
        </div>
      </div>
    </div>
  )
}
