/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from 'react'
import Network from '../helpers/Network'
type Props = {
  setList: any
  list?: any
  url: string
  optionList: object[]
  filter: string
  setFilter: any
}
const DropdownSearch = ({ setList, list, url, optionList, filter, setFilter }: Props) => {
  const filterHandler = async () => {
    if (filter === '0') {
      await Network.getData(url).then((res) => setList(res))
    }
    if (filter === '1') {
      await Network.getData(url).then((res) =>
        setList(res.filter((i: { status: string }) => i.status === 'active'))
      )
    }

    if (filter === '2') {
      await Network.getData(url).then((res) =>
        setList(res.filter((i: { status: string }) => i.status === 'passive'))
      )
    }
  }

  return (
    <div className='menu menu-sub menu-sub-dropdown w-250px w-md-300px' data-kt-menu='true'>
      <div className='px-7 py-5'>
        <div className='fs-5 text-dark fw-bolder'>Filter Options</div>
      </div>

      <div className='separator border-gray-200'></div>

      <div className='px-7 py-5'>
        <div className='mb-10'>
          <label className='form-label fw-bold'>Select the page you are looking for:</label>

          <div>
            <select
              className='form-select form-select-solid'
              data-kt-select2='true'
              data-placeholder='Select option'
              data-allow-clear='true'
              defaultValue={'all'}
              onChange={(e) => setFilter(e.target.value)}
            >
              <option value='all'>All</option>
              {optionList.map((item: any, index: number) => {
                return (
                  <option key={index} value={item.value}>
                    {item.name}
                  </option>
                )
              })}

            </select>
          </div>
        </div>

        {/* <div className='d-flex justify-content-end'>
          <button
            type='reset'
            className='btn btn-sm btn-light btn-active-light-primary me-2'
            data-kt-menu-dismiss='true'
          >
            Close
          </button>

          <button
            onClick={filterHandler}
            type='submit'
            className='btn btn-sm btn-primary'
            data-kt-menu-dismiss='true'
          >
            Apply
          </button>
        </div> */}
      </div>
    </div>
  )
}

export default DropdownSearch
