import React, {useState} from 'react'
import {KTIcon, toAbsoluteUrl} from '../../../../_metronic/helpers'
import Network from '../../../helpers/Network'
import GameConfiguration from '../tabcomponents/GameConfigurations'
import {Modal} from '@mantine/core'
import { ServiceProps } from '../../../helpers/_models'

type PlayerDetail = {
  currentPlayer: any
  setWindowTab: React.Dispatch<React.SetStateAction<string>>
  windowTab: string
  playerDetailHandler?: any
}

const PlayerDetails: React.FC<PlayerDetail> = ({
  currentPlayer,
  setWindowTab,
  windowTab,
  playerDetailHandler,
}) => {
  const [tabData, setTabData] = useState<ServiceProps>()
  const [banModal, setBanModal] = useState(false)
  const [banOkey, setBanOkey] = useState(false)
  const [ban, setBan] = useState({
    ban: currentPlayer.ban,
    ban_reason: currentPlayer.ban_reason || '',
  })
  const RenderOne = () => {
    return (
      <div className='d-flex flex-column mt-4'>
        <div className='p-2'>
          {currentPlayer.ban ? (
            <button className='btn btn-danger' onClick={() => updateUser('unban')}>
              Ban Kaldır
            </button>
          ) : (
            <button className='btn btn-success' onClick={() => setBanModal(true)}>
              Banla
            </button>
          )}
        </div>
        <div className='d-flex flex-column allowed-list'>
          <div className='d-flex align-items-start'>
            <div className='ms-1 w-100 d-flex flex-column align-items-start justify-content-start'>
              <div className='d-flex flex-column allowed-list w-100'>
                <div className='d-flex align-items-center p-2'>
                  <span className='fw-bold'>Player Name: </span>
                  <span className='ms-1'> {currentPlayer?.operator_player_name}</span>
                </div>
                <div className='d-flex align-items-center p-2'>
                  <span className='fw-bold'>Player Id: </span>
                  <span className='ms-1'> {currentPlayer?.operator_player_id}</span>
                </div>
                <div className='d-flex align-items-center p-2'>
                  <span className='fw-bold'>Ban Status: </span>
                  <span className='ms-1'>
                    {' '}
                    {!currentPlayer?.ban ? (
                      <div className='d-flex align-items-center'>
                        <KTIcon
                          iconType='duotone'
                          iconName='shield-tick'
                          className='fs-2 text-success'
                        />{' '}
                        Bansız
                      </div>
                    ) : (
                      <div className='d-flex align-items-center'>
                        {' '}
                        <KTIcon
                          iconType='duotone'
                          iconName='shield-cross'
                          className='fs-2 text-danger'
                        />
                        Banlı
                      </div>
                    )}
                  </span>
                </div>
                {currentPlayer.ban && (
                  <div className='d-flex align-items-center p-2'>
                    <span className='fw-bold'>Ban Reason: </span>
                    <span className='ms-1'> {currentPlayer?.ban_reason}</span>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className='d-flex flex-column allowed-list w-100'>
          <div className='d-flex justify-content-between'>
            <h3 className='p-2'>Operator Relation</h3>
            <span
              onClick={() => {
                removeOperation(currentPlayer?.id)
              }}
              className='d-flex align-items-center cursor-pointer btn btn-bg-light btn-active-color-primary btn-sm mb-2'
            >
              <KTIcon iconName='trash' iconType='duotone' className='fs-2' /> Remove
            </span>
          </div>

          <div className='d-flex align-items-center p-2'>
            <span className='fw-bold'>Operator Name: </span>
            <span className='ms-1'> {currentPlayer?.operator_relation?.name}</span>
          </div>
          <div className='d-flex align-items-center p-2'>
            <span className='fw-bold'>API Endpoint: </span>
            <span className='ms-1'> {currentPlayer?.operator_relation?.api_endpoint}</span>
          </div>
          <div className='d-flex align-items-center p-2'>
            <span className='fw-bold'>API Key: </span>
            <span
              className='ms-1 overflow-hidden'
              title={currentPlayer?.operator_relation?.api_key}
              style={{textOverflow: 'ellipsis'}}
            >
              {currentPlayer?.operator_relation?.api_key}
            </span>
          </div>
          <div className='d-flex align-items-center p-2'>
            <span className='fw-bold'>API Secret: </span>
            <span
              className='ms-1 overflow-hidden'
              title={currentPlayer?.operator_relation?.api_secret}
              style={{textOverflow: 'ellipsis'}}
            >
              {currentPlayer?.operator_relation?.api_secret}
            </span>
          </div>
          <div className='d-flex align-items-center p-2'>
            <span className='fw-bold'>Operator Status: </span>
            <span className='ms-1'>
              {currentPlayer?.operator_relation?.status === 'active' ? (
                <KTIcon iconName='shield-tick' iconType='duotone' className='fs-3 text-success' />
              ) : (
                <KTIcon iconName='shield-cross' iconType='duotone' className='fs-3 text-danger' />
              )}
            </span>
          </div>
        </div>
      </div>
    )
  }

  const tabServices = async (type: string, id: string) => {
    if (type === '2') {
      // Configuration
      await Network.getData(`player-game-configurations/${id}/player`).then((res) => {
        setTabData(res)
      })
    }
    if (type === '3') {
      //Transactions
      await Network.getData(`player-transactions/${id}/type/game`).then((res) => {
        setTabData(res)
      })
    }
    if (type === '4') {
      //Histories
      await Network.getData(`player-game-history/${id}/player`).then((res) => {
        setTabData(res)
      })
    }
    if (type === '5') {
      //Sessions
      //   await Network.getData(`player-game-configurations/${id}/player`).then((res) => {
      //     setTabData(res)
      //   })
    }
  }
  const updateUser = async (type: string) => {
    if (type === 'ban') {
      await Network.putData(`players/${currentPlayer.id}/ban`, ban).then(async (res) => {
        await playerDetailHandler()
        setBanModal(false)
      })
    }
    if (type === 'unban') {
      await Network.putData(`players/${currentPlayer.id}/ban`, {
        ban: false,
        ban_reason: ' ',
      }).then(async (res) => {
        await playerDetailHandler()
      })
    }
  }
  const removeOperation = async (id: any) => {
    await Network.deleteData(`players/${id}/operator`).then(async (_) => {
      await playerDetailHandler()
    })
  }

  return (
    <div className='d-flex flex-column bg-white' style={{height: 'calc(100vh - 150px)'}}>
      <ul className='nav nav-tabs nav-line-tabs fs-6 px-4 d-flex justify-content-between position-relative'>
        <li
          className='nav-item d-flex justify-content-center cursor-pointer'
          style={{width: '20%'}}
          onClick={() => setWindowTab('1')}
        >
          <span className={`nav-link ${windowTab === '1' && 'active'}`}>Player Details</span>
        </li>
        <li
          onClick={() => {
            tabServices('2', currentPlayer.id)
            setWindowTab('2')
          }}
          className='nav-item d-flex justify-content-center cursor-pointer'
          style={{width: '20%'}}
        >
          <span className={`nav-link ${windowTab === '2' && 'active'}`}>Game Configurations</span>
        </li>
        <li
          onClick={() => {
            tabServices('3', currentPlayer.id)
            setWindowTab('3')
          }}
          className='nav-item d-flex justify-content-center cursor-pointer'
          style={{width: '20%'}}
        >
          <span className={`nav-link ${windowTab === '3' && 'active'}`}>Game Transactions</span>
        </li>
        <li
          onClick={() => {
            tabServices('4', currentPlayer.id)
            setWindowTab('4')
          }}
          className='nav-item d-flex justify-content-center cursor-pointer'
          style={{width: '20%'}}
        >
          <span className={`nav-link ${windowTab === '4' && 'active'}`}>Game Histories</span>
        </li>
        <li
          onClick={() => {
            tabServices('5', currentPlayer.id)
            setWindowTab('5')
          }}
          className='nav-item d-flex justify-content-center cursor-pointer'
          style={{width: '20%'}}
        >
          <span className={`nav-link ${windowTab === '5' && 'active'}`}>Sessions</span>
        </li>
      </ul>
      <div className='tab-content' id='myTabContent'>
        {windowTab === '1' && RenderOne()}
        {windowTab === '2' && (
          <GameConfiguration

            mountData={() => tabServices('2', currentPlayer.id)}
            tabData={tabData}
            currentPlayer={currentPlayer}
          />
        )}
     
        {windowTab === '3' && 'Transactions'}    {/* Transactions */}
        {windowTab === '4' && 'Histories'}    {/* Histories */}
        {windowTab === '5' && 'Sessions'}   {/* Sessions */}
      </div>

      <Modal
        size={'lg'}
        opened={banModal}
        title={
          <span className='fw-bold fs-2'>
            {currentPlayer.operator_player_name} adlı kişiyi banlıyorsunuz
          </span>
        }
        onClose={function (): void {
          setBanModal(false)
        }}
        sx={{borderBottom: '2px solid gray'}}
      >
        <Modal.Header className='d-flex justify-content-center fs-3 text-center'>
          {!banOkey ? (
            <div className='d-flex flex-column'>
              <span className='mb-5'>
                {currentPlayer.operator_player_name} adlı kişiyi banlamak istediğinizden emin
                misiniz ?
              </span>
              <span>
                <button
                  className='btn btn-secondary'
                  onClick={() => {
                    setBanOkey(false)
                    setBan({...ban, ban: false})
                    setBanModal(false)
                  }}
                >
                  İptal
                </button>
                <button
                  className='btn btn-primary ms-2'
                  onClick={() => {
                    setBanOkey(true)
                    setBan({...ban, ban: true})
                  }}
                >
                  Evet
                </button>
              </span>
            </div>
          ) : (
            <div className='w-100'>
              <div className='w-100'>
                <label htmlFor='countryName' className='mb-1'>
                  Ban Reason{' '}
                </label>
                <textarea
                  className='form-control form-control-sm form-control-solid w-100'
                  name=''
                  id=''
                  placeholder='Ban nedeni yazın...'
                  cols={50}
                  rows={4}
                  value={ban.ban_reason}
                  onChange={(e) => setBan({...ban, ban_reason: e.target.value})}
                ></textarea>
              </div>

              <button className='btn btn-primary mt-2 w-100' onClick={() => updateUser('ban')}>
                Banla
              </button>
            </div>
          )}
        </Modal.Header>
      </Modal>
    </div>
  )
}

export default PlayerDetails
