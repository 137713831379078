/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from 'react'
import {CardsWidget17, CardsWidget20} from '../../../_metronic/partials/widgets'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import Network from '../../helpers/Network'
import {ListsWidget1} from './widgets/ListsWidget1'
import {TablesWidget1} from './widgets/TableWidget1'
import {LocalStorageProps} from '../../helpers/_models'



const Dashboard: React.FC = () => {
  const [domains, setDomains] = useState<any>({})
  const [currencies, setCurrencies] = useState<any>({})
  const [countries, setCountries] = useState<any>({})
  const [companies, setCompanies] = useState<any>({})
  const [games, setGames] = useState<any>({})
  const [players, setPlayers] = useState<any>({})
  const [userInfo, setUserInfo] = useState<LocalStorageProps>()
  console.log(userInfo)
  const getAllLanguage = async () => {
    await Network.getData(`operator-domains/${userInfo?.data?.operator_id}`)
      .then((res) => {
        setDomains(res)
      })
      .catch((err) => console.log(err))
  }
  const getAllCurrency = async () => {
    await Network.getData(`operator-currencies/${userInfo?.data?.operator_id}`)
      .then((res) => {
        setCurrencies(res)
      })
      .catch((err) => console.log(err))
  }
  const getAllCountry = async () => {
    await Network.getData(`/operator-countries/${userInfo?.data?.operator_id}/countries`)
      .then((res) => {
        setCountries(res)
      })
      .catch((err) => console.log(err))
  }
  const getAllCompany = async () => {
    // await Network.getData(`/operator-companies/${userInfo?.data?.operator_id}/companies`)
    //   .then((res) => {
    //     setCompanies(res)
    //   })
    //   .catch((err) => console.log(err))
  }
  const getAllGames = async () => {
    await Network.getData(`/operator-games/${userInfo?.data?.operator_id}`)
      .then((res) => {
        setGames(res)
      })
      .catch((err) => console.log(err))
  }
  const getAllPlayers = async () => {
    await Network.getData(`/players/${userInfo?.data.operator_id}/operator`)
      .then((res) => {
        setPlayers(res)
      })
      .catch((err) => console.log(err))
  }
  console.log(userInfo)
  useEffect(() => {
    if (userInfo) {
      getAllLanguage()
      getAllCurrency()
      getAllCountry()
      getAllCompany()
      getAllGames()
      getAllPlayers()
    }
  }, [userInfo])
  useEffect(() => {
    const storedData = localStorage.getItem('kt-auth-react-v')
    if (storedData !== null) {
      setUserInfo(JSON.parse(storedData))
    }
  }, [])

  return (
    <>
      <div className='mb-10 p-2'>
        <>
          {/* begin::Row */}
          <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
            {/* begin::Col */}
            <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-3 mb-md-5 mb-xl-10'>
              <CardsWidget20
                className='h-md-50 mb-5 mb-xl-10'
                description='Total Players'
                color='#F1416C'
                img={toAbsoluteUrl('/media/patterns/vector-1.png')}
                count={players?.total}
                pendingCount={players?.data?.filter((i: {ban: boolean}) => !i?.ban).length}
              />
              <CardsWidget17
                className='h-md-50 mb-5 mb-xl-10'
                totalCount={countries?.total}
                activeCount={
                  countries?.data?.filter((i: {country_relation: {status:string}}) => i.country_relation.status === 'active')?.length
                }
                passiveCount={
                  countries?.data?.filter((i: {country_relation: {status:string}}) => i.country_relation.status === 'passive')?.length
                }
                content='Countries'
                chartId='country'
              />
            </div>
            {/* end::Col */}

            {/* begin::Col */}
            <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-3 mb-md-5 mb-xl-10'>
              <CardsWidget17
                className='h-md-50 mb-5 mb-xl-10'
                totalCount={domains?.total}
                activeCount={
                  domains?.data?.filter((i: {operator_relation: {status:string}}) => i.operator_relation.status === 'active')?.length
                }
                passiveCount={
                  domains?.data?.filter((i: {operator_relation: {status:string}}) => i.operator_relation.status === 'passive')?.length
                }
                content='Domains'
                chartId='language'
              />
              <CardsWidget17
                className='h-md-50 mb-5 mb-xl-10'
                totalCount={currencies?.total}
                activeCount={
                  currencies?.data?.filter((i: {currency_relation: {status:string}}) => i.currency_relation.status === 'active')?.length
                }
                passiveCount={
                  currencies?.data?.filter((i: {currency_relation: {status:string}}) => i.currency_relation.status === 'passive')?.length
                }
                content='Currencies'
                chartId='currency'
              />
            </div>
            {/* end::Col */}

            {/* begin::Col */}
            <div className='col-xxl-6'>
              <TablesWidget1
                mountData={getAllGames}
                className='mb-5 mb-xl-8 h-md-100'
                data={games}
                setData={setGames}
                tablehead={['Name', 'Status']}
                addedButton={false}
                userInfo={userInfo}
              />
            </div>
            {/* end::Col */}
          </div>
          {/* end::Row */}

        </>
      </div>
    </>
  )
}
export {Dashboard}
