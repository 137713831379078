import React, {ChangeEvent, useEffect, useState} from 'react'
import Network from '../../../helpers/Network'

interface DataItems {
  operator_id?: any
  operator_player_id?: string
  operator_player_name: string
  ban: boolean
  ban_reason: string
}
type ModalProps = {
  addPlayersValue: DataItems
  setAddPlayersValue: any
  inputTypes: string
}

const PlayerModal: React.FC<ModalProps> = ({addPlayersValue, setAddPlayersValue, inputTypes}) => {
  const [operators, setOperators] = useState([])

  const mountProvider = async () => {
    await Network.getData('/operator')
      .then((res) => {
        setOperators(res.data)
      })
      .catch((err) => console.log(err))
  }

  useEffect(() => {
    mountProvider()
  }, [])

  return (
    <div className='d-flex flex-column align-items-center w-100 p-4'>
      {inputTypes === 'save' && (
        <>
          <div className='position-relative w-100 m-4'>
            <label htmlFor='playerName' className='mb-1 ms-1'>
              Player Name
            </label>
            <input
              type='text'
              id='playerName'
              className='form-control form-control-sm form-control-solid'
              name='operator_player_name'
              value={addPlayersValue.operator_player_name}
              onChange={(e) =>
                setAddPlayersValue({...addPlayersValue, [e.target.name]: e.target.value})
              }
              placeholder='Type Name'
            />
          </div>
          <div className='position-relative w-100 m-4'>
            <label htmlFor='operator_player_id' className='mb-1 ms-1'>
              Player Id
            </label>
            <input
              type='text'
              id='operator_player_id'
              className='form-control form-control-sm form-control-solid '
              name='operator_player_id'
              value={addPlayersValue.operator_player_id}
              onChange={(e) =>
                setAddPlayersValue({...addPlayersValue, [e.target.name]: e.target.value})
              }
              placeholder='Type Player Id'
            />
          </div>
          <div className='position-relative w-100 m-4'>
            <label htmlFor='selectOperator' className='mb-1 ms-1'>
              Select Operator
            </label>
           
          </div>
        </>
      )}

      <div className='position-relative w-100 m-4 d-flex justify-content-between align-items-end'>
        <div className='mb-10' style={{width: '190px'}}>
          <div className='form-check form-switch form-check-custom form-check-solid'>
            <input
              checked={addPlayersValue.ban}
              className='form-check-input h-20px w-30px'
              type='checkbox'
              value=''
              id='flexSwitchDefault'
              onChange={(e) => {
                setAddPlayersValue({...addPlayersValue, ban: e.currentTarget.checked})
              }}
            />
            <label className='form-check-label' htmlFor='flexSwitchDefault'>
              Is Banned
            </label>
          </div>
        </div>
        {addPlayersValue.ban && (
          <div className='mb-10' style={{flex: '1'}}>
            <label htmlFor='ban_reason' className='mb-1 ms-1'>
              Ban Reason
            </label>
            <input
              type='text'
              id='ban_reason'
              className='form-control form-control-sm form-control-solid '
              name='ban_reason'
              value={addPlayersValue.ban_reason}
              onChange={(e) =>
                setAddPlayersValue({...addPlayersValue, [e.target.name]: e.target.value})
              }
              placeholder='Type Ban Reason'
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default PlayerModal
