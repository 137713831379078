import React, { useEffect, useState } from 'react'
import { TablesWidget } from './widgets/TableWidget'
import Network from '../../helpers/Network'
import { LocalStorageProps } from '../../helpers/_models'
const PlayerPage: React.FC = () => {
  const [players, setPlayers] = useState([])
  const [activePage, setActivePage] = useState(1)
  const [countPerPage, setCountPerPage] = useState(10)
  const [totalData, setTotalData] = useState<any>(null)
  const [userInfo, setUserInfo] = useState<LocalStorageProps>()
  const mount = async () => {
    try {
      const res = await Network.getData(`/players/${userInfo?.data.operator_id}/operator?page=${activePage}&&take=${countPerPage}`)
      setPlayers(res.data)
      setTotalData(res.total)
    } catch (error) {
      console.log(error)
    }
  }


  useEffect(() => {
    const storedData = localStorage.getItem('kt-auth-react-v')
    if (storedData !== null) {
      setUserInfo(JSON.parse(storedData))
    }
  }, [])
  useEffect(() => {
    if (userInfo) {
      const reMount = async () => {
        try {
          const res = await Network.getData(`/players/${userInfo?.data.operator_id}/operator?page=${activePage}&&take=${countPerPage}`)
          setPlayers(res)
          setTotalData(res.total)
        } catch (error) {
          console.log(error)
        }
      }
      reMount()
    }

  }, [countPerPage, activePage, userInfo])

  return (
    <div className='h-100'>
      <TablesWidget
        mountData={mount}
        className='mb-5 mb-xl-8'
        data={players}
        setData={setPlayers}
        tablehead={['Player Name', 'Player Realations', 'Ban Status', 'Actions']}
        setActivePage={setActivePage}
        setCountPerPage={setCountPerPage}
        activePage={activePage}
        countPerPage={countPerPage}
        totalData={totalData}
        userInfo={userInfo}
      />
    </div>
  )
}

export { PlayerPage }
