import { useIntl } from 'react-intl'
import { MenuItem } from './MenuItem'
import { MenuInnerWithSub } from './MenuInnerWithSub'
import { MegaMenu } from './MegaMenu'

export function MenuInner() {
  const intl = useIntl()
  return (
    <>
      <MenuItem title={intl.formatMessage({ id: 'MENU.DASHBOARD' })} to='/dashboard' />
      <MenuItem title="Players" to='/players' />

      <MenuInnerWithSub
        title='Actions'
        to='/actions'
        menuPlacement='bottom-start'
        menuTrigger='click'
      >
      
       
        <MenuInnerWithSub
          title='Game Actions'
          to='/actions/game'
          hasArrow={true}
          icon='abstract-17'
          menuPlacement='right-start'
          menuTrigger={`{default:'click', lg: 'hover'}`}
        >
          <MenuItem to='/actions/game/list' title='Game List' hasBullet={true} />
          
        </MenuInnerWithSub>
        <MenuInnerWithSub
          title='Service Actions'
          to='/actions/service'
          hasArrow={true}
          icon='abstract-9'
          menuPlacement='right-start'
          menuTrigger={`{default:'click', lg: 'hover'}`}
        >
          <MenuItem to='/actions/service/countries' title='Countries' hasBullet={true} />
          <MenuItem to='/actions/service/currencies' title='Currencies' hasBullet={true} />
          {/* <MenuItem to='/actions/service/languages' title='Languages' hasBullet={true} /> Language Servisi Yok */} 
        </MenuInnerWithSub>
       
      </MenuInnerWithSub>

     
    </>
  )
}
