import React, { useState } from 'react'
import DropdownSearch from '../../patterns/DropdownSearch'
import { KTIcon } from '../../../_metronic/helpers'

const SearchPage: React.FC = () => {
  const [data, setData] = useState([])
  const [filter, setFilter] = useState('all')

  const label =
    filter === 'all'
      ? 'Search all pages'
      : filter === 'players'
      ? 'Search on Players Page'
      : filter === 'companies'
      ? 'Search on Players Page'
      : filter === 'games'
      ? 'Search on Games Page'
      : filter === 'operators'
      ? 'Search on Operators Page'
      : filter === 'providers'
      ? 'Search on Providers Page'
      : filter === 'services'
      ? 'Search on Services Page'
      : filter === 'integrations'
      ? 'Search on Integrations Page'
      : ''

  return (
    <div className='card mb-10 p-2'>
      <div className='p-4 d-flex w-100 align-items-end justify-content-between'>
        <div className='form-group flex-1' style={{width: '100%', flex: 1}}>
          <label className='fw-bold mb-2'>{label}</label>
          <div className='input-icon input-icon-right'>
            <input type='text' className='form-control p-2' placeholder='Search...' />
            <span>
              <i className='flaticon2-search-1 icon-md'></i>
            </span>
          </div>
        </div>
        <div className='ms-1'>
          <div className='card-toolbar'>
            {/* begin::Menu */}
            <button
              type='button'
              className='btn me-1 btn-sm btn-icon btn-color-primary btn-active-light-primary btn-bg-light'
              style={{minWidth: '150px'}}
              data-kt-menu-trigger='click'
              data-kt-menu-placement='bottom-end'
              data-kt-menu-flip='top-end'
            >
              <KTIcon iconName='category' className='fs-2' />{' '}
              <span className='ms-2 fs-3'>Filters</span>
            </button>
            <DropdownSearch
              optionList={[
                {name: 'Players', value: 'players'},
                {name: 'Games', value: 'games'},
                {name: 'Companies', value: 'companies'},
                {name: 'Operators', value: 'operators'},
                {name: 'Providers', value: 'providers'},
                {name: 'Services', value: 'services'},
                {name: 'Integrations', value: 'integrations'},
              ]}
              setFilter={setFilter}
              filter={filter}
              setList={setData}
              url='/games'
            />
            {/* end::Menu */}
          </div>
        </div>
      </div>
    </div>
  )
}

export default SearchPage
